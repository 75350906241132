var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"time\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"timeConfirmed") || (depth0 != null ? lookupProperty(depth0,"timeConfirmed") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"timeConfirmed","hash":{},"data":data,"loc":{"start":{"line":5,"column":22},"end":{"line":5,"column":39}}}) : helper)))
    + "</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"label label-danger\"> "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"canceled",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":21,"column":42},"end":{"line":21,"column":73}}}))
    + "</span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"label label-danger\"> "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"needs_payment",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":24,"column":42},"end":{"line":24,"column":78}}}))
    + "</span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"label label-warning\"> "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"pickup",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":27,"column":43},"end":{"line":27,"column":72}}}))
    + "</span><br/>\n        "
    + alias2(((helper = (helper = lookupProperty(helpers,"customerName") || (depth0 != null ? lookupProperty(depth0,"customerName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"customerName","hash":{},"data":data,"loc":{"start":{"line":28,"column":8},"end":{"line":28,"column":24}}}) : helper)))
    + "\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <span class=\"label label-info\"> "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"delivery",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":30,"column":40},"end":{"line":30,"column":71}}}))
    + "</span><br/>\n        "
    + alias2(((helper = (helper = lookupProperty(helpers,"addressLine1") || (depth0 != null ? lookupProperty(depth0,"addressLine1") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"addressLine1","hash":{},"data":data,"loc":{"start":{"line":31,"column":8},"end":{"line":31,"column":24}}}) : helper)))
    + "<br/>\n        "
    + alias2(((helper = (helper = lookupProperty(helpers,"addressLine2") || (depth0 != null ? lookupProperty(depth0,"addressLine2") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"addressLine2","hash":{},"data":data,"loc":{"start":{"line":32,"column":8},"end":{"line":32,"column":24}}}) : helper)))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"loader-wrapper\" data-name=\"information-item\">\n    <div class=\"loader\"></div>\n</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isTodayRequested") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":0},"end":{"line":6,"column":7}}})) != null ? stack1 : "")
    + "<div class=\"time-requested\">\n    <div class=\"mt-3 mb-3\">\n        <span class=\"label label-default\">\n            "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/calendarTime.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"dateExpected") : depth0),"","false",{"name":"common$calendarTime","hash":{},"data":data,"loc":{"start":{"line":10,"column":12},"end":{"line":10,"column":59}}}))
    + "\n        </span>\n    </div>\n    <div class=\"mb-1\">\n        <span class=\"label label-default\">\n            "
    + alias2(((helper = (helper = lookupProperty(helpers,"timeRequested") || (depth0 != null ? lookupProperty(depth0,"timeRequested") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"timeRequested","hash":{},"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":15,"column":29}}}) : helper)))
    + "\n        </span>\n    </div>\n</div>\n<div class=\"details\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isCanceled") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":4},"end":{"line":22,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPaid") : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":4},"end":{"line":25,"column":15}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isPickup") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data,"loc":{"start":{"line":26,"column":4},"end":{"line":33,"column":11}}})) != null ? stack1 : "")
    + "</div>\n<div class=\"summary\">\n    "
    + alias2(((helper = (helper = lookupProperty(helpers,"totalPrice") || (depth0 != null ? lookupProperty(depth0,"totalPrice") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"totalPrice","hash":{},"data":data,"loc":{"start":{"line":36,"column":4},"end":{"line":36,"column":18}}}) : helper)))
    + " ("
    + alias2(((helper = (helper = lookupProperty(helpers,"totalItems") || (depth0 != null ? lookupProperty(depth0,"totalItems") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"totalItems","hash":{},"data":data,"loc":{"start":{"line":36,"column":20},"end":{"line":36,"column":34}}}) : helper)))
    + ")\n</div>\n\n";
},"useData":true});