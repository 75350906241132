var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"EUR",(depth0 != null ? lookupProperty(depth0,"value") : depth0),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":3,"column":35},"end":{"line":3,"column":72}}}));
},"3":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <tr>\n        <td colspan=\"2\"><!-- Empty column --></td>\n        <td class=\"text-right\" colspan=\"2\"><small class=\"status\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"errorDescription") || (depth0 != null ? lookupProperty(depth0,"errorDescription") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"errorDescription","hash":{},"data":data,"loc":{"start":{"line":10,"column":65},"end":{"line":10,"column":85}}}) : helper)))
    + "</small></td>\n    </tr>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr>\n    <td class=\"description\" colspan=\"4\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"description","hash":{},"data":data,"loc":{"start":{"line":15,"column":40},"end":{"line":15,"column":55}}}) : helper)))
    + "</td>\n</tr>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <tr >\n        <td class=\"qr-row\" colspan=\"4\">\n            <div class=\"qr-box\">\n                <img class=\"qr\" src=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"qrImageUrl") || (depth0 != null ? lookupProperty(depth0,"qrImageUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"qrImageUrl","hash":{},"data":data,"loc":{"start":{"line":22,"column":37},"end":{"line":22,"column":51}}}) : helper)))
    + "\" alt=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"qrUrl") || (depth0 != null ? lookupProperty(depth0,"qrUrl") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"qrUrl","hash":{},"data":data,"loc":{"start":{"line":22,"column":58},"end":{"line":22,"column":67}}}) : helper)))
    + "\" />\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"qrOverlayImageUrl") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":16},"end":{"line":25,"column":23}}})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"qr-buttons\">\n                <button class=\"btn btn-info\" data-action=\"show-large\">"
    + alias4(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"show_big_qr_code",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":28,"column":70},"end":{"line":28,"column":109}}}))
    + "</button>\n                <button class=\"btn btn-danger\" data-action=\"cancel\">"
    + alias4(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"cancel",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":29,"column":68},"end":{"line":29,"column":97}}}))
    + "</button>\n            </div>\n        </td>\n    </tr>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <img class=\"qr-overlay\" src=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"qrOverlayImageUrl") || (depth0 != null ? lookupProperty(depth0,"qrOverlayImageUrl") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"qrOverlayImageUrl","hash":{},"data":data,"loc":{"start":{"line":24,"column":49},"end":{"line":24,"column":70}}}) : helper)))
    + "\" alt=\"Issuer\"/>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <tr >\n        <td colspan=\"4\">\n            <div class=\"input-pin\">\n                <div class=\"pin\">\n                    <strong>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"code") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":40,"column":24},"end":{"line":44,"column":31}}})) != null ? stack1 : "")
    + "                    </strong>:\n                    <div data-region=\"pin\"></div>\n                    <div class=\"pin-buttons\">\n                        <button class=\"btn btn-info\" data-action=\"submit-pin\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"submit",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":48,"column":78},"end":{"line":48,"column":107}}}))
    + "</button>\n                        <button class=\"btn btn-danger\" data-action=\"cancel\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"cancel",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":49,"column":76},"end":{"line":49,"column":105}}}))
    + "</button>\n                    </div>\n                </div>\n                <div class=\"keys\" data-region=\"keys\" data-ui=\"keys\">\n                </div>\n            </div>\n        </td>\n    </tr>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "                            "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"failed_to_charge_{code}_please_enter_correct_pin",depth0,{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":41,"column":28},"end":{"line":41,"column":101}}}))
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "                            "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"failed_to_charge_payment_please_enter_correct_pin",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":43,"column":28},"end":{"line":43,"column":100}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<tr>\n    <td class=\"title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":2,"column":22},"end":{"line":2,"column":31}}}) : helper)))
    + "</td>\n    <td class=\"value\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"value") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":22},"end":{"line":3,"column":79}}})) != null ? stack1 : "")
    + "</td>\n    <td class=\"status\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"status") || (depth0 != null ? lookupProperty(depth0,"status") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"status","hash":{},"data":data,"loc":{"start":{"line":4,"column":23},"end":{"line":4,"column":33}}}) : helper)))
    + "</td>\n    <td data-ui=\"timer\" class=\"timer\"></td>\n</tr>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"errorDescription") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":0},"end":{"line":12,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":0},"end":{"line":17,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"qrImageUrl") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":0},"end":{"line":33,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPin") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":34,"column":0},"end":{"line":57,"column":7}}})) != null ? stack1 : "");
},"useData":true});