var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"dialog--header\">\n    <h2 data-ui=\"title\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"manual_cash_refund",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":24},"end":{"line":2,"column":65}}}))
    + "</h2>\n    <button class=\"dialog--close btn btn-danger\" data-action=\"close\"><i class=\"fa fa-times\"></i></button>\n</div>\n<div class=\"dialog--content\">\n    <p data-ui=\"message\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"the_current_order_will_be_cancelled_when_you_leave_this_page_there_are_payments_which_aren_t_refunded_yet_please_refund_them_before_continuing_dot",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":25},"end":{"line":6,"column":194}}}))
    + "</p>\n</div>\n<div class=\"dialog--footer\">\n    <button class=\"btn btn-warning btn-regular\" data-action=\"confirm\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"refund_{0}_as_cash",(depth0 != null ? lookupProperty(depth0,"total") : depth0),{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":70},"end":{"line":9,"column":118}}}))
    + "</button>\n    <button class=\"btn btn-default btn-regular\" data-action=\"close\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"cancel",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":10,"column":68},"end":{"line":10,"column":97}}}))
    + "</button>\n</div>";
},"useData":true});