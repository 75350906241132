var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<i class=\"fas fa-euro-sign\"></i> "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"displayAmount") || (depth0 != null ? lookupProperty(depth0,"displayAmount") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"displayAmount","hash":{},"data":data,"loc":{"start":{"line":1,"column":33},"end":{"line":1,"column":50}}}) : helper)))
    + "\n\n<div data-region=\"popup\" class=\"hidden\"></div>";
},"useData":true});