var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <div class=\"col-xs-6\">\n        <button class=\"btn btn-primary w-100 h-100 designer-box rounded\" data-action=\"back\">\n            <i class=\"fas fa-arrow-left\"></i>\n            "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"back",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":5,"column":12},"end":{"line":5,"column":39}}}))
    + "\n        </button>\n    </div>\n    <div class=\"col-xs-6\">\n        <div class=\"w-100 h-100 designer-box rounded block-column-center themed-background-light\">\n            <strong class=\"h4 m-0 mb-2\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"total",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":10,"column":40},"end":{"line":10,"column":68}}}))
    + "</strong>\n            <span class=\"text-success h4 m-0\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/currencyFormat.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"currency_iso3") : depth0),(depth0 != null ? lookupProperty(depth0,"value_outstanding_wt") : depth0),{"name":"common$currencyFormat","hash":{},"data":data,"loc":{"start":{"line":11,"column":46},"end":{"line":11,"column":107}}}))
    + "</span>\n        </div>\n    </div>\n</div>\n";
},"useData":true});