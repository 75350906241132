var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"designer-box rounded news-box\">\n\n    <table class=\"table\" style=\"margin: 0;\">\n        <tbody>\n        <tr>\n            <td>\n                <div class=\"row\">\n                    <div class=\"col-xs-1\">\n                        <button class=\"btn btn-primary btn-link\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"canPrevious") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":65},"end":{"line":9,"column":107}}})) != null ? stack1 : "")
    + " data-action=\"previous\">\n                            <i class=\"fa fa-3x fa-arrow-left\"></i>\n                        </button>\n                    </div>\n                    <div class=\"col-xs-10\">\n                        <h3 class=\"text-center\" style=\"padding-top: 5px;\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":14,"column":74},"end":{"line":14,"column":83}}}) : helper)))
    + "</h3>\n                    </div>\n                    <div class=\"col-xs-1 text-right\">\n                        <button class=\"btn btn-primary btn-link\" "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"canNext") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":65},"end":{"line":17,"column":103}}})) != null ? stack1 : "")
    + " data-action=\"next\">\n                            <i class=\"fa fa-3x fa-arrow-right\"></i>\n                        </button>\n                    </div>\n                </div>\n            </td>\n        </tr>\n        <tr>\n            <td style=\"padding: 0;\">\n                <iframe class=\"news-frame\" frameborder=\"0\" sandbox=\"allow-same-origin\" data-ui=\"iframe\"></iframe>\n            </td>\n        </tr>\n        <tr>\n            <td>\n                <p style=\"margin: 0;\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"published_on_{0}",require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/datetime.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"date_published") : depth0),"LLL",{"name":"common$datetime","hash":{},"data":data,"loc":{"start":{"line":31,"column":76},"end":{"line":31,"column":114}}}),{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":31,"column":38},"end":{"line":31,"column":116}}}))
    + "</p>\n            </td>\n        </tr>\n        </tbody>\n    </table>\n\n</div>";
},"useData":true});