var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<i class=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"weatherIconCls") || (depth0 != null ? lookupProperty(depth0,"weatherIconCls") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"weatherIconCls","hash":{},"data":data,"loc":{"start":{"line":6,"column":36},"end":{"line":6,"column":54}}}) : helper)))
    + "\"></i>";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <img src=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"user_image") || (depth0 != null ? lookupProperty(depth0,"user_image") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"user_image","hash":{},"data":data,"loc":{"start":{"line":16,"column":18},"end":{"line":16,"column":32}}}) : helper)))
    + "\" alt=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"user_image",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":16,"column":39},"end":{"line":16,"column":72}}}))
    + "\" class=\"img-responsive\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "        <canvas data-ui=\"initials\" class=\"img-responsive\"></canvas>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"title\">\n    <span class=\"shop-name text-ellipsis\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":2,"column":42},"end":{"line":2,"column":51}}}) : helper)))
    + "</span>\n    <span class=\"pos-name\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"posName") || (depth0 != null ? lookupProperty(depth0,"posName") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"posName","hash":{},"data":data,"loc":{"start":{"line":3,"column":27},"end":{"line":3,"column":38}}}) : helper)))
    + "</span>\n</div>\n<div class=\"weather\">\n    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"weatherIconCls") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":6,"column":67}}})) != null ? stack1 : "")
    + "\n    &nbsp;\n    <span class=\"temperature\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"temperature") || (depth0 != null ? lookupProperty(depth0,"temperature") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"temperature","hash":{},"data":data,"loc":{"start":{"line":8,"column":30},"end":{"line":8,"column":45}}}) : helper)))
    + "º&nbsp;C</span>\n</div>\n<div class=\"datetime\">\n    <span class=\"date\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"date") || (depth0 != null ? lookupProperty(depth0,"date") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"date","hash":{},"data":data,"loc":{"start":{"line":11,"column":23},"end":{"line":11,"column":31}}}) : helper)))
    + "</span>\n    <span class=\"time\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"time") || (depth0 != null ? lookupProperty(depth0,"time") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"time","hash":{},"data":data,"loc":{"start":{"line":12,"column":23},"end":{"line":12,"column":31}}}) : helper)))
    + "</span>\n</div>\n<div class=\"user\" data-action=\"lock\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"user_image") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":15,"column":4},"end":{"line":19,"column":11}}})) != null ? stack1 : "")
    + "    <i class=\"fas fa-lock\"></i>\n</div>";
},"useData":true});