var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isReturnedByCash") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":11,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isReturnedByPin") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":17,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isReturnedByGiftCard") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":4},"end":{"line":23,"column":11}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isReturnedByOther") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":4},"end":{"line":29,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div>\n            <h4>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"refunded_by_cash",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":8,"column":16},"end":{"line":8,"column":55}}}))
    + " <i class=\"fal fa-money-bill-alt\"></i></h4>\n            <span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"returnedByCash") || (depth0 != null ? lookupProperty(depth0,"returnedByCash") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"returnedByCash","hash":{},"data":data,"loc":{"start":{"line":9,"column":18},"end":{"line":9,"column":36}}}) : helper)))
    + "</span>\n        </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div>\n            <h4>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"refunded_to_payment_card",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":14,"column":16},"end":{"line":14,"column":63}}}))
    + " <i class=\"fal fa-credit-card\"></i></h4>\n            <span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"returnedByPin") || (depth0 != null ? lookupProperty(depth0,"returnedByPin") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"returnedByPin","hash":{},"data":data,"loc":{"start":{"line":15,"column":18},"end":{"line":15,"column":35}}}) : helper)))
    + "</span>\n        </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div>\n            <h4>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"refunded_to_giftcard",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":20,"column":16},"end":{"line":20,"column":59}}}))
    + " <i class=\"fal fa-gift-card\"></i></h4>\n            <span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"returnedByGiftCard") || (depth0 != null ? lookupProperty(depth0,"returnedByGiftCard") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"returnedByGiftCard","hash":{},"data":data,"loc":{"start":{"line":21,"column":18},"end":{"line":21,"column":40}}}) : helper)))
    + "</span>\n        </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div>\n            <h4>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"refunded_to_other",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":26,"column":16},"end":{"line":26,"column":56}}}))
    + " <i class=\"fal fa-money-check-alt\"></i></h4>\n            <span>"
    + alias2(((helper = (helper = lookupProperty(helpers,"returnedByOther") || (depth0 != null ? lookupProperty(depth0,"returnedByOther") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"returnedByOther","hash":{},"data":data,"loc":{"start":{"line":27,"column":18},"end":{"line":27,"column":37}}}) : helper)))
    + "</span>\n        </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div>\n        <h4>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"to_pay",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":32,"column":12},"end":{"line":32,"column":41}}}))
    + "</h4>\n        <span class=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"toPayCls") || (depth0 != null ? lookupProperty(depth0,"toPayCls") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"toPayCls","hash":{},"data":data,"loc":{"start":{"line":33,"column":21},"end":{"line":33,"column":33}}}) : helper)))
    + "\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"toPay") || (depth0 != null ? lookupProperty(depth0,"toPay") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"toPay","hash":{},"data":data,"loc":{"start":{"line":33,"column":35},"end":{"line":33,"column":44}}}) : helper)))
    + "</span>\n    </div>\n    <div>\n        <h4>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"spare_change",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":36,"column":12},"end":{"line":36,"column":47}}}))
    + "</h4>\n        <span class=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"spareChangeCls") || (depth0 != null ? lookupProperty(depth0,"spareChangeCls") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"spareChangeCls","hash":{},"data":data,"loc":{"start":{"line":37,"column":21},"end":{"line":37,"column":39}}}) : helper)))
    + "\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"spareChange") || (depth0 != null ? lookupProperty(depth0,"spareChange") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"spareChange","hash":{},"data":data,"loc":{"start":{"line":37,"column":41},"end":{"line":37,"column":56}}}) : helper)))
    + "</span>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div>\n    <h4>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"total",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":8},"end":{"line":2,"column":36}}}))
    + "</h4>\n    <span class=\""
    + alias2(((helper = (helper = lookupProperty(helpers,"totalCls") || (depth0 != null ? lookupProperty(depth0,"totalCls") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"totalCls","hash":{},"data":data,"loc":{"start":{"line":3,"column":17},"end":{"line":3,"column":29}}}) : helper)))
    + "\">"
    + alias2(((helper = (helper = lookupProperty(helpers,"total") || (depth0 != null ? lookupProperty(depth0,"total") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias1,{"name":"total","hash":{},"data":data,"loc":{"start":{"line":3,"column":31},"end":{"line":3,"column":40}}}) : helper)))
    + "</span>\n</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isNegative") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":5,"column":0},"end":{"line":39,"column":7}}})) != null ? stack1 : "");
},"useData":true});