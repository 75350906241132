var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"full-child-height\">\n        <button class=\"btn btn-danger btn-alt btn-no-border\" data-action=\"open-pin-transaction\">\n            <i class=\"fas fa-credit-card\"></i>\n        </button>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"full-child-height\">\n        <button class=\"btn btn-primary btn-alt btn-no-border\" data-action=\"hospitality\">\n            <i class=\"fas fa-utensils\"></i>\n        </button>\n    </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"full-child-height\">\n        <button class=\"btn btn-primary btn-alt btn-no-border\" data-action=\"online-food-order\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"onlineFoodOrderCount") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":12},"end":{"line":27,"column":19}}})) != null ? stack1 : "")
    + "            <i class=\"fas fa-hamburger\"></i>\n        </button>\n    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <span class=\"badge badge-danger\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"onlineFoodOrderCount") || (depth0 != null ? lookupProperty(depth0,"onlineFoodOrderCount") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"onlineFoodOrderCount","hash":{},"data":data,"loc":{"start":{"line":26,"column":49},"end":{"line":26,"column":73}}}) : helper)))
    + "</span>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"full-child-height\">\n        <button class=\"btn btn-primary btn-alt btn-no-border\" data-action=\"calendar\">\n            <i class=\"fas fa-calendar\"></i>\n        </button>\n    </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"full-child-height\">\n        <button class=\"btn btn-primary btn-alt btn-no-border\" data-action=\"repairs\">\n            <i class=\"fas fa-wrench\"></i>\n        </button>\n    </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"full-child-height\">\n        <button class=\"btn btn-primary btn-alt btn-no-border\" data-action=\"daily-reports\">\n            <i class=\"fas fa-file-check\"></i>\n        </button>\n    </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"full-child-height\">\n        <button class=\"btn btn-primary btn-alt btn-no-border\" data-action=\"sticker-print\">\n            <i class=\"fas fa-print\"></i>\n        </button>\n    </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"full-child-height\">\n        <button class=\"btn btn-primary btn-alt btn-no-border\" data-action=\"settings\">\n            <i class=\"fas fa-cogs\"></i>\n        </button>\n    </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"full-child-height\">\n        <button class=\"btn btn-primary btn-alt btn-no-border\" data-action=\"backoffice\">\n            <i class=\"fas fa-briefcase\"></i>\n        </button>\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showOpenPinTransaction") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":7,"column":7}}})) != null ? stack1 : "")
    + "\n<div class=\"full-child-height\">\n    <button class=\"btn btn-primary btn-alt btn-no-border\" data-action=\"checkout\">\n        <i class=\"fas fa-euro-sign\"></i>\n    </button>\n</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showHospitality") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":0},"end":{"line":20,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showOnlineFood") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":0},"end":{"line":31,"column":7}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showCalendar") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":0},"end":{"line":39,"column":7}}})) != null ? stack1 : "")
    + "<div class=\"full-child-height\">\n    <button class=\"btn btn-primary btn-alt btn-no-border\" data-action=\"return-products\">\n        <i class=\"fas fa-exchange-alt\"></i>\n    </button>\n</div>\n<div class=\"full-child-height\">\n    <button class=\"btn btn-alt btn-primary btn-no-border\" data-action=\"customer\">\n        <i class=\"fas fa-users\"></i>\n    </button>\n</div>\n<div class=\"full-child-height\">\n    <button class=\"btn btn-alt btn-primary btn-no-border\" data-action=\"catalog\">\n        <i class=\"fas fa-th-large\"></i>\n    </button>\n</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showRepairs") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":0},"end":{"line":61,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showDailyReports") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":62,"column":0},"end":{"line":68,"column":7}}})) != null ? stack1 : "")
    + "<div class=\"full-child-height\">\n    <button class=\"btn btn-primary btn-alt btn-no-border\" data-action=\"open-orders\">\n        <i class=\"fas fa-browser\"></i>\n    </button>\n</div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showStickerPrint") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":0},"end":{"line":80,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showSettings") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":0},"end":{"line":87,"column":7}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showBackoffice") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":88,"column":0},"end":{"line":94,"column":7}}})) != null ? stack1 : "")
    + "\n<div class=\"connection-status full-child-height\" data-region=\"connection-status\"></div>\n\n<div class=\"bottom full-child-height\">\n    <button class=\"btn btn-alt btn-primary btn-no-border\" data-action=\"drawer\">\n        <i class=\"fas fa-inbox-out\"></i>\n    </button>\n</div>\n\n\n<div data-region=\"popup\" class=\"hidden\"></div>\n";
},"useData":true});