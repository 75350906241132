var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <br/>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"relation_data") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"shipping") : depth0)) != null ? lookupProperty(stack1,"icon") : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":8},"end":{"line":12,"column":15}}})) != null ? stack1 : "")
    + "        "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"shipping") : depth0)) != null ? lookupProperty(stack1,"name") : stack1), depth0))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"pickup_date") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":19,"column":15}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <i class=\""
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"shipping") : depth0)) != null ? lookupProperty(stack1,"icon") : stack1), depth0))
    + "\"></i>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <br>\n            "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/datetime.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"pickup_date") : depth0),"LL",{"name":"common$datetime","hash":{},"data":data,"loc":{"start":{"line":16,"column":12},"end":{"line":16,"column":48}}}))
    + "\n            <br>\n            <small class=\"text-muted mt-2\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"pickup_date",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":18,"column":43},"end":{"line":18,"column":77}}}))
    + "</small>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"label label-success\">"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"yes",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":24,"column":42},"end":{"line":24,"column":68}}}))
    + "</span>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"flex-column\">\n        <button class=\"btn btn-regular btn-danger\" data-action=\"pay\">\n            <i class=\"fas fa-euro-sign\"></i>&nbsp;"
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"pay",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":28,"column":50},"end":{"line":28,"column":76}}}))
    + "\n        </button>\n    </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "            <i class=\"far fa-check-circle\"></i>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "            <i class=\"far fa-box-open\"></i>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "            <button class=\"btn btn-regular btn-primary\" data-action=\"print\" data-ui=\"print\">\n                <i class=\"far fa-print\"></i> "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"print",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":61,"column":45},"end":{"line":61,"column":73}}}))
    + "\n            </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td>\n    #"
    + alias2(((helper = (helper = lookupProperty(helpers,"number") || (depth0 != null ? lookupProperty(depth0,"number") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"number","hash":{},"data":data,"loc":{"start":{"line":2,"column":5},"end":{"line":2,"column":15}}}) : helper)))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_anonymous") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":5,"column":15}}})) != null ? stack1 : "")
    + "    <br/>"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/datetime.js").call(alias1,(depth0 != null ? lookupProperty(depth0,"date_purchased") : depth0),"LLL",{"name":"common$datetime","hash":{},"data":data,"loc":{"start":{"line":6,"column":9},"end":{"line":6,"column":49}}}))
    + "\n</td>\n<td class=\"text-center\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"shipping") : depth0)) != null ? lookupProperty(stack1,"name") : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":20,"column":11}}})) != null ? stack1 : "")
    + "</td>\n<td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_paid") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":23,"column":4},"end":{"line":31,"column":11}}})) != null ? stack1 : "")
    + "</td>\n<td class=\"text-center\">\n    <div class=\"flex-column\">\n    <button class=\"btn btn-regular btn-success\" data-action=\"pickup\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_shipped") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data,"loc":{"start":{"line":36,"column":8},"end":{"line":40,"column":15}}})) != null ? stack1 : "")
    + "        "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"collect",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":41,"column":8},"end":{"line":41,"column":38}}}))
    + "\n    </button>\n    </div>\n</td>\n<td class=\"text-center\">\n    <div class=\"flex-column\">\n    <button class=\"btn btn-regular btn-primary\" data-action=\"details\">\n        "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"details",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":48,"column":8},"end":{"line":48,"column":38}}}))
    + "\n    </button>\n    </div>\n    <div class=\"hidden\" data-region=\"popup\"></div>\n</td>\n<td class=\"text-center\">\n    <div>\n        <div class=\"loader-wrapper\" data-name=\"print\">\n            <div class=\"loader\"></div>\n        </div>\n        <div class=\"flex-column\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPackbonPrint") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":12},"end":{"line":63,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</td>\n";
},"useData":true});