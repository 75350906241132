var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                        <span class=\"input-group-btn\">\n                        <button class=\"btn btn-regular btn-primary\" type=\"button\" data-ui=\"mass-print\">\n                            <i class=\"fa fa-print\"></i> "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"print_all",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":23,"column":56},"end":{"line":23,"column":88}}}))
    + "\n                        </button>\n                    </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <div class=\"col-sm-8\">\n        <div class=\"search-container designer-box rounded full-width\">\n            <form class=\"form\" onsubmit=\"return false;\" data-action=\"form\">\n                <div class=\"input-group\">\n                    <input type=\"text\" class=\"form-control\" placeholder=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"type_here_to_search_for_a_order",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":6,"column":73},"end":{"line":6,"column":127}}}))
    + "\" data-action=\"search\" data-ui=\"search\">\n                    <span class=\"input-group-btn\">\n                        <button class=\"btn btn-regular btn-primary\" data-action=\"clear\" type=\"button\">\n                            "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"search_again",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":9,"column":28},"end":{"line":9,"column":63}}}))
    + "\n                        </button>\n                    </span>\n                </div>\n            </form>\n        </div>\n    </div>\n    <div class=\"col-sm-4\">\n        <div class=\"search-container designer-box rounded full-width\">\n            <form class=\"form\" onsubmit=\"return false;\" data-action=\"form\">\n                <div class=\"input-group\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPackbonPrint") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":26,"column":27}}})) != null ? stack1 : "")
    + "                    <input class=\"form-control\" data-input=\"date\" data-ui=\"pickup-date-filter\" readonly placeholder=\""
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"pickup_date_range",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":27,"column":117},"end":{"line":27,"column":157}}}))
    + "\">\n                    <span class=\"input-group-btn\">\n                        <button class=\"btn btn-regular btn-default\" type=\"button\" data-ui=\"day-picker\">\n                            <i class=\"fas fa-calendar\"></i>\n                        </button>\n                    </span>\n                </div>\n            </form>\n        </div>\n    </div>\n</div>\n\n<div>\n    <div class=\"loader-wrapper\" data-name=\"main\"><div class=\"loader\"></div></div>\n    <div class=\"product-container designer-box rounded\">\n        <div data-region=\"items\"></div>\n\n        <div class=\"more full-child-height\">\n            <button class=\"btn btn-primary btn-block\" data-action=\"more\" data-ui=\"more\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"load_more",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":45,"column":88},"end":{"line":45,"column":120}}}))
    + "</button>\n            <div class=\"loader-wrapper\" data-name=\"more\"><div class=\"loader\"></div></div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});