var Handlebars = require("handlebars");
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <button class=\"btn designer-box rounded reset w-100\" data-action=\"print-returns\">\n            "
    + container.escapeExpression(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(depth0 != null ? depth0 : (container.nullContext || {}),"print_daily_returns",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":31,"column":12},"end":{"line":31,"column":54}}}))
    + "\n        </button>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"loader-wrapper\"><div class=\"loader\"></div></div>\n<h2 class=\"title\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"scan_receipt",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":2,"column":18},"end":{"line":2,"column":53}}}))
    + "</h2>\n<div class=\"block designer-box rounded filters\">\n    <div data-region=\"date\"></div>\n    <div data-region=\"amount\"></div>\n</div>\n<div class=\"block designer-box rounded overview-container\">\n    <div class=\"search\">\n        <div data-region=\"number\"></div>\n        <div data-region=\"product\"></div>\n    </div>\n    <h3 class=\"title\">"
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"the_last_few_receipts",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":12,"column":22},"end":{"line":12,"column":66}}}))
    + "</h3>\n    <div>\n        <div class=\"loader-wrapper\" data-name=\"overview\"><div class=\"loader\"></div></div>\n        <div class=\"overview\" data-region=\"overview\"></div>\n    </div>\n</div>\n<div class=\"controls\">\n    <div class=\"last-receipt full-child-height\">\n        <div class=\"loader-wrapper\" data-name=\"last\"><div class=\"loader\"></div></div>\n        <button class=\"btn designer-box rounded w-100\" data-action=\"last\">\n            "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"last_receipt",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":22,"column":47}}}))
    + "\n        </button>\n    </div>\n    <button class=\"btn designer-box rounded reset w-100\" data-action=\"reset\">\n        "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"reset_search",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":26,"column":8},"end":{"line":26,"column":43}}}))
    + "\n    </button>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"has_receipt_printer") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":4},"end":{"line":33,"column":11}}})) != null ? stack1 : "")
    + "\n    <button class=\"btn designer-box rounded reset w-100\" data-ui=\"print-last-pin-receipt\">\n        "
    + alias2(require("/home/luki/workspace/projects/storekeeper/app-cash-register-ui/app/scripts/modules/common/templates/helpers/translate.js").call(alias1,"print_last_pin_receipt",{"name":"common$translate","hash":{},"data":data,"loc":{"start":{"line":36,"column":8},"end":{"line":36,"column":53}}}))
    + "\n    </button>\n\n    <div class=\"keypad w-100\" data-region=\"keypad\"></div>\n</div>\n\n\n\n<div data-region=\"popup\" class=\"hidden\"></div>\n";
},"useData":true});